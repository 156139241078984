/*
 * Content:
 *
 * Card
 * Card img filter
 * Card link
 * Card link CMS
 * Card with icon CMS
 * Card link square
 * Card thumbnail
 * Card overlay grid
 * Card form
 * Card product
 * Small product suggestion cards
 * Card message
 * Card shorcut
 */

@use "sass:map";


// Card
// ============================================================================

.card {
  align-items: stretch;
}


// Card img filter
// ============================================================================

.card-img-filter {
  @include pseudo-filter(
    $hover-parent-selector: ".card",
    $selector: ".card-img-filter"
  );
}


// Card link
// ============================================================================

.card-link {
  --#{$prefix}card-spacer-y: #{spacer(6)};
  --#{$prefix}card-spacer-x: #{spacer(5)};
  text-decoration: none;
  box-shadow: $box-shadow;

  .card-body {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: spacer(3);
  }
}


// Card link CMS
// ============================================================================

.card-link-cms {
  --#{$prefix}card-spacer-y: #{spacer(5)};
  --#{$prefix}card-color: #{$text-subtitle};
  --#{$prefix}card-border-width: 1px;
  --#{$prefix}card-border-color: var(--#{$prefix}card-bg);
  text-align: center;
  text-decoration: none;
  box-shadow: $box-shadow-lg;
  transition: $btn-transition;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: spacer(2);
    font-size: $font-size-lg;
  }

  &:hover,
  &:focus {
    --#{$prefix}card-bg: #{map.get($theme-colors-light, "secondary")};
    --#{$prefix}card-border-color: #{$secondary};
  }
}


// Card with icon CMS
// ============================================================================

.card-with-icon-cms {
  box-shadow: $box-shadow-sm;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacer(2);
  }

  @include media-breakpoint-up(md) {
    .card-body {
      flex-direction: row;
      align-items: flex-start;
    }
  }
}

.card-with-icon-cms-icon {
  flex-shrink: 0;
}

.card-with-icon-cms-content {
  flex-grow: 1;
  align-self: center;
  width: 100%;
  color: $gray-800;

  @include media-breakpoint-up(md) {
    max-width: 651px;
  }
}

.card-with-icon-cms-space-between {
  @include media-breakpoint-up(md) {
    .card-body {
      justify-content: space-between;
    }

    .card-with-icon-cms-content {
      max-width: 582px;
    }
  }
}


// Card link square
// ============================================================================

.card-link-square {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: rem(50px);
  height: rem(50px);
  margin-right: spacer(1);
  background-color: $primary-bg-subtle;
  border-radius: rem(10px);

  img {
    width: rem(36px);
    height: rem(36px);
    object-fit: contain;
    object-position: center;
  }
}


// Card radio button
// ============================================================================
.card-radio-button {
  position: relative;
  padding: spacer(4);
  cursor: pointer;
  background-color: $card-bg;
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
  transition: all .25s;

  &:has(input[type="radio"]:checked) {
    background-color: map.get($theme-colors-light, "secondary");
    border-color: $secondary;
  }

  .decoration {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .form-check-input {
    flex-shrink: 0;
  }
}

.card-radio-button-shadow {
  border-color: $gray-200;
  box-shadow: $box-drop-shadow-light;
}


// Card thumbnail
// ============================================================================
.card-thumbnail {
  position: relative;
  height: 185px;
  padding: 0;

  margin: 0;

  .badge {
    position: absolute;
    top: spacer(3);
    right: spacer(3);
  }

  img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-position: center;
    object-fit: cover;
  }
}


// Marketing campaign card access
.marketing-campaign-card-access {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border-radius: spacer(4);

  .thumbnail {
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-position: center;
      object-fit: cover;
    }
  }

  .overlay {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-image: $degrade;
    border-radius: spacer(3);
    opacity: .5;
    transform: rotate(180deg);
  }

  .content {
    top: 0;
    left: 0;
    z-index: 20;
    display: block;
    width: 100%;
    height: 100%;
    padding-block: spacer(6);
    padding-inline: spacer(6);

    h4 {
      font-size: $h4-font-size;

      @include media-breakpoint-up(sm) {
        font-size: $h3-font-size;
      }
    }

    .btn {
      $btn-max-width: 350px;
      max-width: $btn-max-width;
    }
  }
}


// Card overlay grid
// ============================================================================

.card-overlay-grid {
  display: grid;
  grid-template: #{$card-overlay-grid-item-height} / 1fr;
  grid-auto-rows: $card-overlay-grid-item-height;
  grid-auto-flow: row;
  gap: spacer(5) spacer(9);

  @include media-breakpoint-up(md) {
    grid-template-columns: 55% 1fr;
  }
}

.card-overlay-grid-item {
  overflow: hidden;
  border-radius: $card-border-radius;

  .card {
    --#{$prefix}card-color: #{$card-overlay-grid-item-color};
    --#{$prefix}card-title-color: #{$card-overlay-grid-item-color};
    --#{$prefix}card-subtitle-color: #{$card-overlay-grid-item-color};
    --#{$prefix}card-border-width: 0;
    --#{$prefix}card-bg: #{$card-overlay-grid-item-bg};
    --#{$prefix}card-img-overlay-bg: #{rgba($card-overlay-grid-item-filter-color, $card-overlay-grid-item-filter-opacity)};
    --#{$prefix}card-hover-img-overlay-bg: #{rgba($card-overlay-grid-item-filter-color, $card-overlay-grid-item-hover-filter-opacity)};
    --#{$prefix}body-color: #{$card-overlay-grid-item-color};
  }

  .card-img {
    height: 100%;
    object-fit: cover;
    transition: transform .25s ease-in-out;
  }

  .card-img-overlay {
    background: var(--#{$prefix}card-img-overlay-bg);
    transition: background .25s ease-in-out;
  }

  &:hover {
    .card-img {
      transform: scale(125%);
    }

    .card-img-overlay {
      background: var(--#{$prefix}card-hover-img-overlay-bg);
    }
  }
}

.card-overlay-grid-item-featured {
  border-radius: $border-radius-lg;
  grid-row-end: span 2;
}


// Card form
// ============================================================================

.card-form {
  display: flex;
  flex-direction: column;
  padding: spacer(4) spacer(5);
  background-color: $gray-100;
  border-radius: $border-radius;
  gap: spacer(4);
}


// Card product
// ============================================================================

.card-product {
  padding: spacer(5) spacer(4);
  border-radius: $border-radius;
  box-shadow: $box-drop-shadow;
}

.card-product-badge {
  display: flex;
  align-items: center;
  width: max-content;
  padding: spacer(2) spacer(4);
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $white;
  background: var(--#{$prefix}card-border-color, #{$secondary});
  margin-inline-start: auto;
  gap: spacer(2);
}

.card-product-recommended,
.card-product-featured {
  --#{$prefix}card-color: #{$gray-800};
  --#{$prefix}card-bg: #{map.get($theme-colors-light, "info")};
  --#{$prefix}card-border-width: #{map.get($border-widths, 2)};
  --#{$prefix}card-title-color: #{$skyblue-dark};
  --#{$prefix}card-title-spacer-y: #{spacer(4)};
}

.card-product-recommended {
  --#{$prefix}card-border-color: #{$secondary};
}

.card-product-featured {
  --#{$prefix}card-spacer-y: #{spacer(5)};
  --#{$prefix}card-spacer-x: #{spacer(5)};
  --#{$prefix}card-border-color: #{$skyblue-middle};
  display: flex;
  flex-direction: column;

  .card-product-badge {
    order: 0;
    margin-left: auto;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: flex-start;

    .card-product-badge {
      order: 1;
    }
  }
}


// Small product suggestion card
// ============================================================================

.small-product-suggestion-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: spacer(5) spacer(4);
  border-radius: $border-radius;
  box-shadow: $box-shadow-lg;
  gap: spacer(6);
}


// Card product secondary
// ============================================================================

.card-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacer(6);
  padding: spacer(5) spacer(6);
  color: $gray-800;
  background: $blue-light;
  border-radius: $border-radius-lg;

  p a {
    font-weight: $font-weight-bold;
    color: currentcolor;
  }

  img {
    @include img-fluid();
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}


// Card shortcut
// ============================================================================

.card-shortcut {
  box-shadow: $box-shadow-sm;

  @include pseudo-filter($filter-opacity: .1);

  .card-body {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: spacer(4);
    padding: spacer(6);
    border-radius: inherit;
  }

  .card-title {
    margin-bottom: 0;
    font-size: $h6-font-size;
    font-weight: $font-weight-normal;
    color: $text-subtitle;
  }
}
