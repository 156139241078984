/*
 * Content:
 *
 * Badge
 */


// Badge
// ============================================================================
.badge {
  padding: spacer(2) spacer(3);
  font-size: $font-size-sm;
  font-weight: 800;
  color: $white;
  border-radius: 2rem;

  a {
    color: inherit;
    text-decoration: none;
  }
}
