/*
 * Content:
 *
 * Modal header
 */


// Modal header
// ============================================================================

.modal-header {
  .btn-close {
    padding: $btn-close-padding-y $btn-close-padding-x;
    margin: 0 0 0 auto;
  }
}
