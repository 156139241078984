/*
 * Content:
 *
 * Legal Basis List
 * List group
 * List bullet
 * List icon
 * List numbered circle
 */


//  Legal Basis List
// ============================================================================
.legal-basis-list {
  padding: 0;
  font-size: $font-size-xs;
  color: $text-body;
  list-style: none;

  & li::before {
    content: "* ";
  }
}

//  List group
// ============================================================================
.list-group {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  gap: spacer(2);
}


// List bullet
// ============================================================================

.list-bullet {
  @include list-unstyled();
  padding-left: $list-bullet-width + $list-bullet-gap + rem(5px);
  color: $gray-800;

  li {
    position: relative;

    &::before {
      position: absolute;
      top: $list-bullet-top;
      left: ($list-bullet-width + $list-bullet-gap) * -1;
      width: $list-bullet-width;
      height: $list-bullet-width;
      content: "";
      background-color: $list-bullet-color;
      border-radius: 50%;
    }

    & + li {
      margin-top: spacer(2);
    }
  }
}


// List icon
// ============================================================================

.list-icon {
  @include list-unstyled();
}

.list-icon-item {
  position: relative;
  min-height: $list-icon-width;
  padding-left: $list-icon-width + $list-icon-gap;
  color: $gray-800;

  &::before {
    position: absolute;
    top: calc((#{$list-icon-width} - 1.5em) * .5 * -1);
    left: 0;
    display: inline-block;
    flex-shrink: 0;
    width: $list-icon-width;
    height: $list-icon-width;
    content: "";
    background-image: var(--list-icon-bg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  & + .list-icon-item {
    margin-top: spacer(2);
  }
}

.list-icon-check {
  --list-icon-bg: #{escape-svg($list-icon-check-bg)};
}

.list-icon-check-info {
  --list-icon-bg: #{escape-svg(str-replace($list-icon-check-bg, #{$list-icon-check-color}, #{$info}))};
}

.list-icon-check-skyblue-middle {
  --list-icon-bg: #{escape-svg(str-replace($list-icon-check-bg, #{$list-icon-check-color}, #{$skyblue-middle}))};
}

.list-icon-error {
  --list-icon-bg: #{escape-svg($list-icon-error-bg)};
}

.list-icon-sm {
  font-size: $font-size-sm;

  .list-icon-item {
    padding-left: $list-icon-width + spacer(4);
  }
}

.list-icon-align-top {
  .list-icon-item::before {
    top: 0;
  }
}

//  List numbered circle
// ============================================================================

.list-numbered-circle {
  @include list-unstyled();
  counter-reset: numbered-circle-list;

  > li {
    position: relative;
    padding-left: rem(34px);
    counter-increment: numbered-circle-list;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: counter(numbered-circle-list);

      @include numbered-circle($font-size: $font-size-sm,$size: rem(24px));
    }

    & + li {
      margin-top: spacer(2);
    }
  }
}
