/*
 * Content:
 *
 * Authentication card
 */


// Authentication card
// ============================================================================

.authentication-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: spacer(6);
  border-radius: $border-radius;
  box-shadow: $box-drop-shadow;
}
