/*
 * Content:
 *
 * Modules
 * Alert
 * Alert link
 * Alert dismissible
 * Variants
 */


// Modules
// ============================================================================

@use "sass:map";


// Alert
// ============================================================================


.alert {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  font-size: $font-size-lg;
  font-weight: $font-weight-normal;
  color: #343a40;
  border: $alert-border-width solid transparent;
  gap: 15px;

  @include border-radius($alert-border-radius);

  &-content {
    flex-grow: 1;
  }
}

// Alert link
// ============================================================================

.alert-link {
  font-weight: $alert-link-font-weight;
  color: inherit;

  &:hover {
    color: inherit;
  }
}


// Alert dismissible
// ============================================================================

.alert-dismissible {
  padding-right: $alert-dismissible-padding-r;

  .btn-close {
    position: absolute;
    top: $alert-padding-y;
    right: $alert-padding-x;
    z-index: $stretched-link-z-index + 1;
    width: $alert-dismissible-icon-size;
    height: $alert-dismissible-icon-size;
    padding: 0;
    background-image: $alert-dismissible-icon-bg;
    background-size: contain;
  }
}


// Variants
// ============================================================================
@include media-breakpoint-down(sm) {
  .alert {
    padding: $alert-padding-y-sm $alert-padding-x-sm;
    gap: 8px;

    &-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .alert-dismissible {
    padding-right: calc(($alert-padding-x-sm * 2) + $alert-dismissible-icon-size);

    .btn-close {
      top: $alert-padding-y-sm;
      right: $alert-padding-x-sm;
    }
  }
}

@each $theme, $data in $alert-themes {
  .alert-#{$theme} {
    @include alert-variant($data...);
  }
}
